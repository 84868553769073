body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Keyframe to make the embers float up and flicker */
@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 0.6;
  }

  50% {
    opacity: 1;
    /* Simulate flicker at the middle */
  }

  100% {
    transform: translateY(-1200px);
    /* Move particles up beyond the top */
    opacity: 0;
    /* Fade out as they move upward */
  }
}

/* Ember particles with glowing effect */
.ember {
  position: absolute;
  bottom: -100px;
  /* Start just below the bottom of the screen */
  width: 12px;
  height: 12px;
  background: radial-gradient(circle, #FF4500, #FF6347, #FFA500);
  /* Fiery gradient */
  border-radius: 50%;
  animation: float 12s infinite ease-in-out;
  opacity: 0.8;
  box-shadow: 0px 0px 20px 5px rgba(255, 69, 0, 0.8);
  /* Bright fiery glow effect */
}

.ember:nth-child(2) {
  width: 8px;
  height: 8px;
  left: 10%;
  animation-duration: 10s;
  animation-delay: 1s;
  background: radial-gradient(circle, #FFA500, #FF4500);
  /* Slightly different ember hue */
}

.ember:nth-child(3) {
  width: 16px;
  height: 16px;
  left: 20%;
  animation-duration: 14s;
  animation-delay: 2s;
  background: radial-gradient(circle, #FF6347, #FFA500);
  /* Larger, slightly brighter ember */
}

.ember:nth-child(4) {
  width: 10px;
  height: 10px;
  left: 35%;
  animation-duration: 9s;
  animation-delay: 3s;
  background: radial-gradient(circle, #FF4500, #FF8C00);
  /* Orange to red gradient */
}

.ember:nth-child(5) {
  width: 18px;
  height: 18px;
  left: 50%;
  animation-duration: 12s;
  animation-delay: 1.5s;
  background: radial-gradient(circle, #FF8C00, #FF4500);
  /* Larger and more orange */
}

.ember:nth-child(6) {
  width: 6px;
  height: 6px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 2.5s;
  background: radial-gradient(circle, #FFA500, #FF4500);
}

.ember:nth-child(7) {
  width: 14px;
  height: 14px;
  left: 80%;
  animation-duration: 15s;
  animation-delay: 3.5s;
  background: radial-gradient(circle, #FF6347, #FF4500);
}

.ember:nth-child(8) {
  width: 20px;
  height: 20px;
  left: 90%;
  animation-duration: 11s;
  animation-delay: 0.5s;
  background: radial-gradient(circle, #FFA500, #FF4500);
  /* Larger bright ember */
}

/* Bounce effect for the image */
@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
    /* Adjust this for bounce height */
  }
}